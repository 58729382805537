$palette: (
  primary: #f00,
  secondary: #0f0,
  // PRIMARY COLORS
  // primary text/backgrround
  darkNavy: #222233,
  darkNavyHighlight: #363644,
  lightNavy: #363644,

  // primary CTA
  electricIndigo: #716bff,
  darkerGrey: #84848a,
  accentGrey: #a7a7ad,
  // Used for line based elements. (INPUTS)
  highlight: #d9dbe9,
  // Used for outlines only. (PANELS)
  outline: #e9ebf5,
  // Used for element backgrounds. (grid etc)
  background: #f7f7fc,
  // Used to avoid darkmode strain. (grey panels on white bg)
  offWhite: #fcfcfc,
  fullWhite: #ffffff,

  // SECONDARY COLORS
  secondaryStart: #42428a,
  secondaryScripting: #b37196,
  secondaryInteractive: #594d6b,
  secondaryInformation: #9d6fb8,
  secondaryTransfer: #c87273,
  secondaryIntegration: #de7450,
  secondaryDTMF: #f4762d,
  secondaryEnd: #ffbc52,

  // SYSTEM COLORS
  error: #fd5254,
  info: #467ee5,
  standard: #737791,
  warning: #ffc262,
  success: #09c590,
  active: #b37196,
  slot: #2f80ed,

  // SHADOW COLORS
  darkNavyShadow: #2222331a,
  overlay: rgba(34, 34, 51, 0.8),
);

$nodeColors: (
  start: map-get($palette, 'secondaryStart'),
);

// Export for JS with color* prefix
:export {
  @each $key, $value in $palette {
    color#{camelCase(#{$key})}: $value;
  }
}
