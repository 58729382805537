$breakpoints: (
  // Predefined breakpoints
  mobile: 500px,
  tablet: 800px,
  desktop: 1000px,
  medium: 1200px,
  large: 1400px
);

// Export for JS with breakpoint* prefix
:export {
  @each $key, $value in $breakpoints {
    breakpoint#{camelCase(#{$key})}: $value;
  }
}
