// IMPORTANT
// This file is here so css vars are only declared once instead of everytime a shared import is used.

@import '../config/easing';

// export breakpoints as CSS vars
:root {
  @each $key, $value in $breakpoints {
    --breakpoint-#{$key}: #{$value};
  }
}

// export colors as CSS vars
:root {
  @each $key, $value in $palette {
    --color-#{$key}: #{$value};
  }
}

// export sizes as CSS vars
:root {
  @each $key, $value in $sizes {
    --sizes-#{$key}: #{$value};
  }
}

:root {
  --col: $col-width;
}
