@import 'functions/strip-unit.scss';
@import 'functions/rem.scss';
@import 'functions/camelCase.scss';

@import 'config/colors';
@import 'config/breakpoints';
@import 'config/sizes';

@import 'mixins/layout-utils';
@import 'mixins/media-queries';
@import 'mixins/min-prop';
@import 'mixins/max-prop';

html {
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: unset;
  font-display: block;
  font-family: 'Proxima Nova', sans-serif;
  -webkit-tap-highlight-color: transparent; /* for removing the highlight on tap/touch */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: var(--color-background);
  color: var(--color-darkNavy);
}

// Prevent 2-finger swipe to trigger history.back in chrome
html,
body {
  overscroll-behavior-x: none;
}

body {
  height: 100%;
  min-height: 100vh;
}

body.noscroll {
  position: fixed;
  overflow: hidden;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

#root {
  height: 100%;
  min-height: 100vh;
}

.focussed {
  outline: 1px solid var(--color-electricIndigo);
}

.error {
  color: var(--color-error);
}
