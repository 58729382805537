@font-face {
  font-family: 'Proxima Nova';
  src: url('/assets/fonts/proximanova-regular-webfont.eot');
  src: url('/assets/fonts/proximanova-regular-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/proximanova-regular-webfont.woff2') format('woff2'),
    url('/assets/fonts/proximanova-regular-webfont.woff') format('woff'),
    url('/assets/fonts/proximanova-regular-webfont.ttf') format('truetype'),
    url('/assets/fonts/proximanova-regular-webfont.svg#proxima_novaregular')
      format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/assets/fonts/proximanova-semibold-webfont.eot');
  src: url('/assets/fonts/proximanova-semibold-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/proximanova-semibold-webfont.woff2') format('woff2'),
    url('/assets/fonts/proximanova-semibold-webfont.woff') format('woff'),
    url('/assets/fonts/proximanova-semibold-webfont.ttf') format('truetype'),
    url('/assets/fonts/proximanova-semibold-webfont.svg#proxima_novasemibold')
      format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: block;
}
